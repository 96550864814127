import './App.css';

function App() {
  return (
    <div className="App flex items-center justify-start min-h-screen">
        <div className="container mx-auto max-w-4xl p-10 sm:p-0">
            <div className="flex flex-col items-start">
                <h1 className="text-2xl font-ABCDiatype mb-8">Mohammed Ibrahim</h1>
                <p className="text-2xl font-ABCDiatypeLight text-left mb-8">My mission is to build software that helps enhance people’s lives in education, finance, and health.</p>
                <p className="text-2xl font-ABCDiatypeLight text-left mb-8">I started a web design agency in highschool where I designed and built websites for local businesses by cold calling through Yellow Pages.</p>
                <p className="text-2xl font-ABCDiatypeLight text-left mb-8">After making some money, I bought a Mac - I've been building for iOS and Mac ever since.</p>
                <p className="text-2xl font-ABCDiatypeLight text-left mb-8">I've built apps at Shopify, Twitter, Apple, and a few startups in between.</p>
                <p className="text-2xl font-ABCDiatypeLight text-left mb-8">Currently, I'm building Chromatic Engineering - a software lab focused on building products that align with my mission.</p>
                <p className="text-2xl font-ABCDiatypeLight text-left mb-8">You can reach me at <a href="https://twitter.com/mohams2001" target="_blank" rel="noopener noreferrer" className="text-blue-500">@mohams2001</a>.</p>
            </div>
        </div>
    </div>
  );
}

export default App;
